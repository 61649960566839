#root {
    min-height: 100%;
}

body,
html {
    font-family: sans-serif;
    background-color: black;
    min-height: 100%;
    height: 100%;
    margin: 0;
    background-image: url("./bgimage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

a {
    text-decoration: none;
}

a:hover {
    color: #ffffff;
}

.overlay {
    color: #cccccc;
    font-size: 100vh;
    font-weight: bold;
    opacity: 0.01;
    position: fixed;
    text-align: center;
    height: 100%;
    width: 100%;
    user-select: none; /* Standard syntax */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    -webkit-user-select: none; /* Safari 3.1+ */
}

.playlist {
    list-style: none;
    color: #cccccc;
    font-family: monospace;
    background: black;
    opacity: 0.8;
    margin: 0px;
    min-height: 100vh;
    height: 100%;
    font-size: large;
}

.links {
    position: fixed;
    font-size: 3vh;
    bottom: 0;
    width: 100%;
    text-align: center;
    opacity: 0.8;
    font-weight: bold;
}

.link {
    padding-left: 0.4em;
    color: #cccccc;
}

.selected {
    color: #333333;
}

.playlist-title {
    margin: 0px;
}

.not-found {
    width: 100%;
    height: 100%;
    font-size: 50vh;
    font-weight: bold;
    opacity: 0.8;
    position: fixed;
    text-align: center;
    color: #ffffff;
}

.icon {
    height: 12pt;
    padding: 0px 5px;
    vertical-align: middle;
}
